.app-tour-tooltip {
  max-width: 420px;
  min-width: 290px;
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  background-color: $custom-white;

  &__body {
    padding: 1rem;
  }

  &__footer {
    padding: 1rem;
  }

}
