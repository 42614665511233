.model-general {
  .model-general-info {
    &__wrap {
      @media (max-width: 575.98px) {
        flex-direction: column;
      }
    }

    &__avatar-block {
      position: relative;
      margin-right: 1rem;

      .image-wrap {
        width: 169px;
        height: auto;
        aspect-ratio: 1 / 1.6;
        @media (max-width: 575.98px) {
          width: 100%;
          aspect-ratio: 1;
        }
      }
    }

    .info-list__block {
      margin-right: 1rem;

      @media (max-width: 575.98px) {
        margin-right: 0;
      }
    }

    .info-list__info {
      &_group {
        &:after {
          content: ", ";
        }
        &:last-child:after {
          content: "";
        }
      }
    }

    .info-list__block:last-of-type {
      margin-right: 0;
    }

    .info-list__item {
      &_red-highlight {
        background-color: $brand-danger;
        color: $custom-white;
        padding: 2px;
        border-radius: 2px;
      }
    }
  }

  .model-contacts-info {
    &__content-title {
      font-weight: 700;
    }

    &__content {
    }
  }

  .model-features-info {
    .info-list__label {
      font-weight: 500;
      width: 50px;
      //white-space: nowrap;

      @media (max-width: 767.98px) {
        width: 60px;
      }
    }

    .info-list__block:last-of-type {
      .info-list__label {
        width: 70px;

        @media (max-width: 767.98px) {
          width: 80px;
        }
      }
    }

    .info-list__info:last-of-type {
      margin-right: 0.25rem;
    }
  }

  .in-mark {
    background-color: $brand-secondary;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px $brand-light;
    position: relative;
    top: 1px;
  }
}

$gutter: 8;

.model-edit-avatar-drawer {
  .model-uploaded-photos-grid {
    .grid-item,
    .grid-sizer {
      margin-bottom: #{$gutter}px; // gutter
      width: masonry-grid-item-width(8, $gutter);

      @media (max-width: 1399.98px) {
        width: masonry-grid-item-width(6, $gutter);
      }

      @media (max-width: 991.98px) {
        width: masonry-grid-item-width(4, $gutter);
      }

      @media (max-width: 767.98px) {
        width: masonry-grid-item-width(3, $gutter);
      }

      @media (max-width: 575.98px) {
        width: masonry-grid-item-width(2, $gutter);
      }
    }
  }

  .model-uploaded-photo-card {
    width: 100%;

    &__photo-block {
      position: relative;
      display: flex;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    &__avatar-badge-block {
      position: absolute;
      top: 0.75rem;
      left: 0.5rem;
    }

    &__avatar-badge {
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-user-select: none;
      user-select: none;
      background-color: $brand-secondary;
      border: none;
      padding: 0;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      line-height: 1rem;
      font-size: 14px;
      color: $custom-white;
    }
  }

  // hotfix
  .model-avatar-crop-stepper {
    .RFS-LabelContainer {
      @media (max-width: 767.98px) {
        font-size: 1rem;
      }
    }
  }
}

.drawer {
  .model-features-full-view {
    .info-list {
      &__item {
        @media (min-width: 768px) and (max-width: 1199.98px) {
          flex-direction: column;
        }
      }

      &__label {
        width: 120px;

        @media (max-width: 767.98px) {
          width: 130px;
        }
      }
    }
  }
}
