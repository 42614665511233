@import "src/assets/scss/giulia-variables";

.list {
  margin: 0;
  padding-left: 0;
  list-style-position: inside;
}

.listItem {
  list-style: none;
  line-height: 1.65;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.item {
  display: flex;
  flex-grow: 1;
}

.itemBody {
  flex-grow: 1;
  display: flex;
  font-size: 1rem;
}

.itemIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;

  width: 22px;
  height: 22px;
  border-radius: 50%;
  flex-shrink: 0;
}