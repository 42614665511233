.events-calendar {
  &.calendar {
  }
}

.events-calendar-toolbar {
  display: flex;
  flex-direction: column;

  &__category-block {
    display: flex;
    margin-bottom: 1.5rem;

    flex-wrap: wrap;
    margin-left: -0.25rem;
    margin-right: -0.25rem;

    .calendar-event-category-col {
      width: calc(100% / 6);
      padding-left: 0.25rem;
      padding-right: 0.25rem;

      @media (max-width: 767.98px) {
        width: calc(100% / 3);
        margin-bottom: 0.5rem;
      }
    }
  }

  &__subbar-block {
    margin-bottom: 1.5rem;
    display: flex;
  }

  &__filter-block {
  }

  .event-type-filter {
    width: 200px;

    @media (max-width: 575.98px) {
      width: 150px;
    }
  }

  &__action-bar-block {
    display: flex;
    width: 100%;

    @media (max-width: 575.98px) {
      margin-left: 1rem;
    }

    .calendar-action-bar {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;

      .other-events-dropdown {
        color: $event-text-color;
        box-shadow: none;
      }

      .other-events-btn {
        color: $event-text-color;
        box-shadow: none;
        width: 120px;
      }
    }
  }
}

.calendar-event_theme {
  &_note {
    background-color: $note-color;
    color: $event-text-color;
  }

  &_out {
    background-color: $out-color;
    color: $event-text-color;
  }

  &_scouting {
    background-color: $scouting-color;
    color: $event-text-color;
  }

  &_go_see {
    background-color: $go_see-color;
    color: $event-text-color;
  }

  &_casting {
    background-color: $casting-color;
    color: $event-text-color;
  }

  &_fitting {
    background-color: $fitting-color;
    color: $event-text-color;
  }

  &_option {
    background-color: $option-color;
    color: $event-text-color;
  }

  &_job {
    background-color: $job-color;
    color: $event-text-color;
  }

  &_other {
    background-color: $other-category-event-color;
    color: $event-text-color;
  }
}

.calendar-day-header-content {
  display: flex;

  @media (max-width: 575.98px) {
    flex-direction: column;
  }

  &__main {
    width: 100%;
    overflow: auto;
  }

  &__right-block {
    display: flex;
    margin-left: 0.5rem;

    @media (max-width: 575.98px) {
      margin-left: 0;
      justify-content: center;
    }
  }
}

.print-events-content {
  &__event-item {
    border-bottom: 1px solid $custom-gray-300;
  }
}
