//CUSTOM-COLORS
$custom-white: #ffffff;
$custom-black: #000;
$custom-lavender: #d9ecf1;
$custom-blue-highlight: #0CA0C3;

$custom-gray-50: #f8fafc;
$custom-gray-100: #f1f5f9;
$custom-gray-200: #f7f7f7;
$custom-gray-300: #cbd5e1;
$custom-gray-400: #94a3b8;
$custom-gray-500: #64748b;
$custom-gray-600: #475569;
$custom-gray-700: #304958;
$custom-gray-800: #1e293b;
$custom-gray-900: #0f172a;

$custom-blue-gray-50: #f1f8fa;
$custom-blue-gray-100: #ddecf0;
$custom-blue-gray-200: #bedae3;
$custom-blue-gray-300: #91c0cf;
$custom-blue-gray-400: #5d9cb3;
$custom-blue-gray-500: #418099;
$custom-blue-gray-600: #396981;
$custom-blue-gray-700: #34586a;
$custom-blue-gray-800: #304958;
$custom-blue-gray-900: #2c404d;

$custom-blue: #4c99e5;
$custom-indigo: #564ab1;
$custom-purple: #6f42c1;
$custom-sky: #0090b7;
$custom-cyan: #50a5f1;
$custom-red: #ea503f;
$custom-green: #26c582;
$custom-pink: #ea95cd;
$custom-lime: #83aa5d;
$custom-orange: #ffa41d;
$custom-yellow: #fecd39;
$custom-dirty-yellow: #c9c914;
$custom-teal: #01b7aa;
$custom-gray: #64748b;
$custom-blue-gray: #418099;

//CUSTOM COLORS TINTS AND SHADES
//https://uicolors.app/create

$custom-blue-50: #ecf4fc;
$custom-blue-100: #dbeafa;
$custom-blue-200: #b7d6f5;
$custom-blue-300: #93c2ef;
$custom-blue-400: #70adea;
$custom-blue-500: $custom-blue;
$custom-blue-600: #207dd9;
$custom-blue-700: #1861a8;
$custom-blue-800: #114577;
$custom-blue-900: #0a2846;

$custom-indigo-50: #cdcae9;
$custom-indigo-100: #c0bbe3;
$custom-indigo-200: #a59fd7;
$custom-indigo-300: #8a82cb;
$custom-indigo-400: #7065bf;
$custom-indigo-500: #564ab1;
$custom-indigo-600: #4c419b;
$custom-indigo-700: #413886;
$custom-indigo-800: #372f70;
$custom-indigo-900: #2c265b;

$custom-purple-50: #d8ccee;
$custom-purple-100: #cdbde9;
$custom-purple-200: #b59edf;
$custom-purple-300: #9e7fd5;
$custom-purple-400: #8661cb;
$custom-purple-500: #6f42c1;
$custom-purple-600: #5d36a4;
$custom-purple-700: #4c2c86;
$custom-purple-800: #3a2267;
$custom-purple-900: #291848;

$custom-sky-50: #cbf4ff;
$custom-sky-100: #adedff;
$custom-sky-200: #70e0ff;
$custom-sky-300: #32d3ff;
$custom-sky-400: #00c0f4;
$custom-sky-500: #0090b7;
$custom-sky-600: #007898;
$custom-sky-700: #00607a;
$custom-sky-800: #00485b;
$custom-sky-900: #00303d;

$cyan-50: #f0f8fe;
$cyan-100: #dceffd;
$cyan-200: #c1e4fc;
$cyan-300: #97d4f9;
$cyan-400: #65bbf5;
$cyan-500: $custom-cyan;
$cyan-600: #2c80e4;
$cyan-700: #236bd2;
$cyan-800: #2357aa;
$cyan-900: #224b86;

$custom-red-50: #fce7e4;
$custom-red-100: #fad6d2;
$custom-red-200: #f6b5ad;
$custom-red-300: #f29389;
$custom-red-400: #ee7264;
$custom-red-500: $custom-red;
$custom-red-600: #e62e1a;
$custom-red-700: #c22615;
$custom-red-800: #9d1f11;
$custom-red-900: #79180d;

$custom-green-50: #b2f0d6;
$custom-green-100: #a1edcd;
$custom-green-200: #7fe6bb;
$custom-green-300: #5de0a9;
$custom-green-400: #3bd996;
$custom-green-500: $custom-green;
$custom-green-600: #1fa36b;
$custom-green-700: #198155;
$custom-green-800: #125e3e;
$custom-green-900: #0c3c28;

$custom-pink-50: #fdf5fa;
$custom-pink-100: #fbeaf5;
$custom-pink-200: #f7d5eb;
$custom-pink-300: #f2c0e1;
$custom-pink-400: #eeaad7;
$custom-pink-500: $custom-pink;
$custom-pink-600: #e166b7;
$custom-pink-700: #d737a1;
$custom-pink-800: #b32482;
$custom-pink-900: #841a60;

$custom-lime-50: #dfe9d5;
$custom-lime-100: #d5e2c8;
$custom-lime-200: #c0d4ad;
$custom-lime-300: #acc693;
$custom-lime-400: #97b878;
$custom-lime-500: $custom-lime;
$custom-lime-600: #6f924c;
$custom-lime-700: #5a773e;
$custom-lime-800: #465c30;
$custom-lime-900: #324122;

$custom-orange-50: #ffeed5;
$custom-orange-100: #ffe6c0;
$custom-orange-200: #ffd597;
$custom-orange-300: #ffc56f;
$custom-orange-400: #ffb446;
$custom-orange-500: $custom-orange;
$custom-orange-600: #f39100;
$custom-orange-700: #ca7900;
$custom-orange-800: #a26100;
$custom-orange-900: #794800;

$custom-yellow-50: #fff5d9;
$custom-yellow-100: #fff1c7;
$custom-yellow-200: #ffe8a4;
$custom-yellow-300: #fedf80;
$custom-yellow-400: #fed65d;
$custom-yellow-500: $custom-yellow;
$custom-yellow-600: #fec310;
$custom-yellow-700: #e4ac01;
$custom-yellow-800: #bc8d01;
$custom-yellow-900: #936f01;

$custom-teal-50: #effefc;
$custom-teal-100: #c7fff6;
$custom-teal-200: #8effef;
$custom-teal-300: #4efae6;
$custom-teal-400: #1ae7d5;
$custom-teal-500: $custom-teal;
$custom-teal-600: #00a49b;
$custom-teal-700: #03827d;
$custom-teal-800: #086765;
$custom-teal-900: #0c5553;

$custom-dirty-yellow-50: #fcfcde;
$custom-dirty-yellow-100: #f9f9c4;
$custom-dirty-yellow-200: #f4f491;
$custom-dirty-yellow-300: #efef5e;
$custom-dirty-yellow-400: #eaea2b;
$custom-dirty-yellow-500: $custom-dirty-yellow;
$custom-dirty-yellow-600: #c9c914;
$custom-dirty-yellow-700: #a4a410;
$custom-dirty-yellow-800: #7f7f0d;
$custom-dirty-yellow-900: #5a5a09;
