@mixin for-size($size) {
  @if $size == mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $size == tablet {
    @media (min-width: 768px) and (max-width: 1199px) {
      @content;
    }
  } @else if $size == desktop {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

.pricing-block {
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@include for-size(mobile) {
  .pricing-block {
    &__list {
      align-items: center;
      flex-direction: column;
    }
  }
}

@include for-size(tablet) {
  .pricing-block {
    &__list {
      justify-content: center;
    }
  }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
}
