.modal {
}

.modalContent {
  border-radius: 0;
  background-color: black;
  border: none;
}

.modalBody {
  padding: 0;
}

.videoPlayer {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.videoPlayer::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.videoPlayer video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}