@import "../../variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

.introjs-tooltipReferenceLayer * {
  font-family: "Poppins", sans-serif;
}

.introjs-tooltip {
  .introjs-button {
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    line-height: $btn-line-height;
    color: $gray-200;
    text-align: center;
    text-decoration: if($link-decoration == none, null, none);
    white-space: $btn-white-space;
    vertical-align: middle;
    cursor: if($enable-button-pointers, pointer, null);
    user-select: none;
    background-color: $brand-secondary;
    border: $btn-border-width solid transparent;
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
    @include transition($btn-transition);

    border-radius: 0.125rem;
    text-shadow: none;

    &:hover {
      background-color: #007a9c;
      border-color: #007392;
    }

    &:focus {
      background-color: #007a9c;
      border-color: #007392;
      box-shadow: 0 0 0 0.15rem rgba(38, 161, 194, 0.5);
    }

    &:active {
      background-color: #007392;
      border-color: #006c89;
    }

    &:disabled,
    &.introjs-disabled,
    fieldset:disabled & {
      pointer-events: none;
      opacity: $btn-disabled-opacity;
      @include box-shadow(none);
    }
  }
}