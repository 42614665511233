.models-panel-sidebar {
  .form-checkbox-group {
    .form-check:last-of-type {
      margin-left: 1.25rem;
    }
  }

  .models {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .models__block {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
    }

    .models-item {
      margin: 0.75rem 0;

      &_active {
        .models-item__title {
          color: $brand-secondary;
        }
      }

      a:hover {
        .models-item__title {
          color: $brand-secondary;
        }
      }

      &__block {
        display: flex;
        align-items: center;
      }

      &__avatar-block {
        position: relative;
      }

      &__in-town-block {
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &__in-town-mark {
        background-color: $brand-secondary;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        box-shadow: inset 0 0 0 2px $custom-white;
      }

      &__title-block {
        margin-left: 0.75rem;
        display: flex;
        align-items: center;
      }

      &__title {
        font-size: 1rem;
        font-weight: 500;
        color: #303030;
      }
    }
  }
}
