@import "src/assets/scss/giulia-variables";

.swatch {
  width: 28px;
  height: 28px;
  border-radius: 8px;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.popover_content {
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background-color: $brand-secondary-light;
  padding: 0.5rem;
}

.input_wrapper {
  background-color: $brand-secondary-light;
  padding: 0.6rem;
  display: flex;
  justify-content: center;
  border-radius: 0 0 9px 9px;

  input {
    display: block;
    box-sizing: border-box;
    width: 90px;
    padding: 6px;
    border: 1px solid $brand-secondary;
    border-radius: 4px;
    background-color: white;
    outline: none;
    font: inherit;
    text-transform: uppercase;
    text-align: center;
  }

  input:focus {
    border-color: $brand-secondary;
  }
}

.popover_color_picker {
  border-radius: 9px;
  border: none;
  outline: none;
}
